import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    addARecord,
    editARecord,
    fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const viewerChoices = [
    "customer",
    "brand",
    "builder",
    "architect",
];

const getCapitalised = (s) => s[0].toUpperCase() + s.slice(1);

const standardizeArray = s => {
    let o = {};
    let S = getCapitalised(s);
    o["key"] = S;
    o["label"] = S;
    o["value"] = s;

    return o;
};


const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [image, setImage] = useState();
    const [initialImage, setInitialImage] = useState(null);
    const [selectedViewers, setSelectedViewers] = useState([]);




    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top",
        });
    };

    const currentFormMode = useSelector((store) => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(
        (store) => store.master?.currentEditViewFormId
    );
    const fetchRecordStatus = useSelector(
        (store) => store.master?.fetchRecordStatus
    );
    const editRecordStatus = useSelector(
        (store) => store.master?.editRecordStatus
    );
    const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
    const currentRecordData = useSelector(
        (store) => store.master?.currentRecordData
    );


    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImage();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImage();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);

        if (currentRecordData?.image) {
            setInitialImage(currentRecordData?.image);
        }

        if (currentRecordData?.showing_to) {
            setSelectedViewers(currentRecordData?.showing_to?.map?.(standardizeArray));
        }

    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (
            currentEditViewFormId &&
            (currentFormMode === FormModesEnum.EDIT ||
                currentFormMode === FormModesEnum.VIEW)
        ) {
            if (!currentEditViewFormId) {
                openNotification(
                    "Something went wrong",
                    "Please contact developers. Id was not provided while changing modes",
                    "error"
                );
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const handleFileChange = (e, type) => {
        const file = e.target.files[0];

        if (type === "image") {
            setImage(file || null);
        };
    };

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({
            apiUrl: apiUrl, data: val, headers: {
                "Content-Type": "multipart/form-data",
            },
        }));
    };

    const edit = (val) => {
        // console.log(val);
        dispatch(editARecord({
            apiUrl, id: currentEditViewFormId, data: val, headers: {
                "Content-Type": "multipart/form-data",
            }
        }));
    };

    const onFinish = (val) => {

        val.images = null;
        console.log({ selectedViewers });

        if (image) {
            val.image = image;  // Only image files are pushed to `images`
        }

        if (selectedViewers && selectedViewers.length > 0) {
            // Map to just the "value" field (which represents the viewer type)
            val.showing_to = selectedViewers.map(viewer => viewer.value);
        } else {
            val.showing_to = [];  // Ensure it's always an array, even if empty
        }

        val.showing_to = JSON.stringify(val.showing_to);

        console.log("Final payload:", val);

        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    };

    const onSelectViewersChange = function (value, option) {
        console.log({ value, option });
        setSelectedViewers(prev => {
            let r = JSON.parse(JSON.stringify(prev));
            if (Array.isArray(r)) {
                r.push(option);
            } else {
                r = [];
                r.push(option);
            }
            return r;
        });
    };

    const onDeselectViewersChange = function (value, option) {
        console.log({ value, option });
        let ss = JSON.parse(JSON.stringify(selectedViewers));
        if (Array.isArray(ss)) {
            // 
        } else {
            ss = [];
        }
        ss = ss.filter(r => r.value !== value);
        setSelectedViewers(ss);
    };

    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                {/* Title */}
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Please input the title!" }]}
                >
                    <Input placeholder="Enter advertisement title" />
                </Form.Item>

                {/* URL */}
                <Form.Item
                    label="Ad URL"
                    name="url"
                    rules={[
                        { required: true, type: "url", message: "Please input a valid URL!" }
                    ]}
                >
                    <Input type="url" placeholder="Enter URL (optional)" />
                </Form.Item>

                {/* Image */}
                <Form.Item
                    label="Ad Image"
                    // name="image"
                    rules={[{ required: true, message: "Please upload an image!" }]}
                >
                    {image ? (
                        <img
                            src={URL.createObjectURL(image)}
                            alt="Preview"
                            className="img-fluid mb-3"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                            }}
                        />
                    ) : initialImage ? (
                        <img
                            src={initialImage}
                            alt="Initial"
                            className="img-fluid mb-3"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                            }}
                        />
                    ) : (
                        currentFormMode !== FormModesEnum.ADD && <p>No image available</p>
                    )}
                    {(currentFormMode === FormModesEnum.ADD ||
                        currentFormMode === FormModesEnum.EDIT) && (
                            <input
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, "image")}
                            />
                        )}
                </Form.Item>

                {/* Is Enabled */}
                <Form.Item
                    label="Enable Advertisement"
                    name="is_enabled"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label="Showing to"
                    name="showing_to"
                    rules={[
                        {
                            required: true,
                            message: "Viewers are required"
                        }
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '300px' }}
                        // loading={availablePermissionsLoading}
                        value={selectedViewers}
                        options={viewerChoices?.map?.(standardizeArray)}
                        onSelect={onSelectViewersChange}
                        onDeselect={onDeselectViewersChange}
                    />
                </Form.Item>

                {/* Time Interval */}
                <Form.Item
                    label="Time Interval (in seconds)"
                    name="time_interval"
                // rules={[{ required: true, message: "Please input the time interval!" }]}
                >
                    <InputNumber
                        min={5}
                        placeholder="Enter time interval in seconds"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                {/* Views */}
                <Form.Item label="Views" name="views">
                    <InputNumber
                        min={0}
                        placeholder="Enter the number of views (optional)"
                        style={{ width: "100%" }}
                        disabled
                    />
                </Form.Item>

                {/* Submit Button */}
                {currentFormMode === FormModesEnum.ADD ? (
                    <Form.Item>
                        {addRecordStatus === asyncStatuses.LOADING ? (
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Submitting
                            </Button>
                        ) : (
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        )}
                    </Form.Item>
                ) : currentFormMode === FormModesEnum.EDIT ? (
                    <Form.Item>
                        {editRecordStatus === asyncStatuses.LOADING ? (
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Updating
                            </Button>
                        ) : (
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        )}
                    </Form.Item>
                ) : null}
            </Form>

        </>
    );
};
export default AddEditForm;               
