import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [brandOptions, setBrandOptions] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productSubTypeOptions, setProductSubTypeOptions] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState(null);
  const [pdf, setPdf] = useState();
  const [initialPdf, setInitialPdf] = useState(null);

  const fetchBrands = async () => {
    try {
      const response = await axios.get("/user/rest/brand-info/");
      console.log(response);
      setBrandOptions(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const fetchProductCategories = async () => {
    try {
      const response = await axios.get("/product/product-category/");
      console.log(response);
      setProductCategories(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const fetchProductTypes = async () => {
    try {
      const response = await axios.get("/product/product-types/");
      console.log(response);
      setProductTypeOptions(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const fetchProductSubTypes = async () => {
    try {
      const response = await axios.get("/product/product-sub-types/");
      console.log(response);
      setProductSubTypeOptions(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    fetchProductCategories();
    fetchProductTypes();
    fetchProductSubTypes();
  }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);

    if (Array.isArray(currentRecordData?.media) && currentRecordData.media.length > 0) {
      setInitialImage(currentRecordData.media[0]?.file || null);
      setInitialPdf(currentRecordData.media[1]?.file || null);
    } else {
      setInitialImage(null);
      setInitialPdf(null);
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    if (type === "image") {
      setImage(file || null);
    } else if (type === "pdf") {
      setPdf(file || null);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({
      apiUrl: apiUrl, data: val, headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({
      apiUrl, id: currentEditViewFormId, data: val, headers: {
        "Content-Type": "multipart/form-data",
      }
    }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;

    // if (image) {
    //   val.media = [];
    // }

    // if (pdf) {
    //   val.media = [...(val.media || []), file: pdf ];
    // }

    val.images = null;
    val.brochure = null;

    if (image) {
      val.images = image;  // Only image files are pushed to `images`
    }

    if (pdf) {
      val.brochure = pdf;  // The PDF file is assigned to `brochure`
    }

    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      form.resetFields();
    } else {
      console.log("No suitable mode found");
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Brand"
          name="brand"
          rules={[{ required: true, message: "Please select a brand!" }]}
        >
          <Select placeholder="Select a brand">
            {brandOptions?.map((brand) => (
              <Option key={brand.id} value={brand.id}>
                {brand.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input placeholder="Enter product title" />
        </Form.Item>

        <Form.Item
          label="Product Category"
          name="product_category"
          rules={[{ required: true, message: "Please select a product category!" }]}
        >
          <Select placeholder="Select a product category">
            {productCategories?.map((type) => (
              <Option key={type.validue} value={type.id}>
                {type.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Product Type"
          name="product_type"
          rules={[{ required: true, message: "Please select a product type!" }]}
        >
          <Select placeholder="Select a product type">
            {productTypeOptions?.map((type) => (
              <Option key={type.validue} value={type.id}>
                {type.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Product Sub Type"
          name="product_sub_type"
          rules={[{ required: true, message: "Please select a product sub type!" }]}
        >
          <Select placeholder="Select a product sub type">
            {productSubTypeOptions?.map((type) => (
              <Option key={type.validue} value={type.id}>
                {type.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please input the description!" }]}
        >
          <Input.TextArea placeholder="Enter product description" />
        </Form.Item>

        <Form.Item
          label="Features"
          name="features"
          rules={[{ required: true, message: "Please input the features!" }]}
        >
          <Input.TextArea placeholder="Enter product features" />
        </Form.Item>

        <Form.Item
          label="Color"
          name="color"
          rules={[{ required: true, message: "Please input the color!" }]}
        >
          <Input placeholder="Enter product color" />
        </Form.Item>

        <Form.Item
          label="Texture"
          name="texture"
          rules={[{ required: true, message: "Please input the texture!" }]}
        >
          <Input placeholder="Enter product texture" />
        </Form.Item>

        <Form.Item
          label="Cost"
          name="cost"
          rules={[{ required: true, message: "Please input the cost!" }]}
        >
          <Input placeholder="Enter product cost" />
        </Form.Item>

        <Form.Item
          label="Size"
          name="size"
          rules={[{ required: true, message: "Please input the size!" }]}
        >
          <Input placeholder="Enter product size" />
        </Form.Item>
        <Form.Item
          label="Image"
          rules={[{ required: true, message: "Please upload an image!" }]}>

          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No image available</p>
          )}
          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && <input type="file" id="imageUpload" multiple onChange={(e) => handleFileChange(e, 'image')} />}
        </Form.Item>
        <Form.Item label="PDF">
          {initialPdf ? (
            <a href={initialPdf} target="_blank" rel="noopener noreferrer">
              View PDF
            </a>
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No PDF available</p>
          )}
          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && (
            <input type="file" accept="application/pdf" onChange={(e) => handleFileChange(e, "pdf")} />
          )}
        </Form.Item>
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;               
