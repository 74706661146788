import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons";
import axios from 'axios';
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [projects, setProjects] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);

  const fetchProjects = async () => {
    try {
      const response = await axios.get("/project/projects/");
      console.log(response);
      setProjects(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching users list");
    }
  };

  const fetchRoomTypes = async () => {
    try {
      const response = await axios.get("/project/room-types/");
      console.log(response);
      setRoomTypes(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching users list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    fetchProjects();
    fetchRoomTypes();
  }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);

    if (currentRecordData?.media?.length > 0 && currentRecordData.media[0]?.file) {
      setInitialImage(currentRecordData.media[0].file);
    }

  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({
      apiUrl: apiUrl, data: val, headers: {
        "Content-Type": "multipart/form-data",
      }
    }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({
      apiUrl, id: currentEditViewFormId, data: val, headers: {
        "Content-Type": "multipart/form-data",
      }
    }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;

    val.media = Array.isArray(val.media) ? val.media : [];

    if (image) {
      // If there's already a media object for the image, update it
      if (val.media.length > 0) {
        val.media[0] = { ...val.media[0], file: image };
      } else {
        // Otherwise, add a new object
        val.media.push({ file: image });
      }
    }
    
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      setImage(null);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      setImage(null);
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          name="project"
          label="Project"
          rules={[{ required: true, message: 'Please select a project!' }]}
        >
          <Select placeholder="Select a project">
            {projects.map(project => (
              <Option key={project.id} value={project.id}>{project.project_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="room_type"
          label="Room Type"
          rules={[{ required: true, message: 'Please select a room type!' }]}
        >
          <Select placeholder="Select a room type">
            {roomTypes.map(roomType => (
              <Option key={roomType.id} value={roomType.id}>{roomType.title}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Media"
          rules={[]}
        >
          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No image available</p>
          )}

          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && <input
            type="file"
            accept="image/*"
            id="imageUpload"
            onChange={handleImageChange}
          />}
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;