import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { allowedFields } from "Utils/allowedFiltersEnum";
import responseCodeMessagesEnum from "Utils/responseCodeMessages.enum";
import searchParamsEnum from "Utils/searchParamsEnum";
import axios from "axios";

const PAGINATION_SIZE = 12;

const generateSearchParams = (extraApiParams, searchParams, pageNumber) => {
  console.log({ extraApiParams, allowedFields });

  for (let field of allowedFields) {
    searchParams.delete(field);
  }

  for (let field in extraApiParams) {
    searchParams.set(field, extraApiParams[field]);
  }

  if (typeof pageNumber === "number") {
    searchParams.set(searchParamsEnum.PAGE, pageNumber);
  } else {
    searchParams.set(searchParamsEnum.PAGE, 1);
  }
};

const isCurrentPageNumberPresentInNext = (thunkAPI, pageNumber) => {
  let currentState = thunkAPI.getState();
  let currentData = currentState?.master?.data;
  let currentDataLength = 0;

  if (Array.isArray(currentData)) {
    currentDataLength = currentData.length;
  }

  let currentPageNumberIsPresentInNext = currentState?.master?.next?.includes?.(
    `${searchParamsEnum.PAGE}=${pageNumber}`
  );
  console.log({ currentState, currentPageNumberIsPresentInNext, pageNumber });

  let validPageNumber = pageNumber;
  if (!currentPageNumberIsPresentInNext) {
    if (currentDataLength < PAGINATION_SIZE) {
      validPageNumber = 1;
    }
  }

  return { currentPageNumberIsPresentInNext, validPageNumber };
};

const getValidApiUrl = (apiUrl) => {
  let validApiUrl = apiUrl;

  if (validApiUrl?.slice?.(-1) !== "/") {
    validApiUrl += "/";
  }

  return validApiUrl;
};

export const fetchAllRecords = createAsyncThunk(
  "rest/all/list",
  async (
    { apiUrl, extraApiParams, searchParams = {}, pageNumber },
    thunkAPI
  ) => {
    try {
      console.log({ apiUrl, extraApiParams, searchParams, pageNumber });

      let { currentPageNumberIsPresentInNext, validPageNumber } =
        isCurrentPageNumberPresentInNext(thunkAPI, pageNumber);

      console.log("fetchAllRecords", { currentPageNumber: pageNumber, validPageNumber });
      const validApiUrl = apiUrl.includes("?")
        ? apiUrl
        : getValidApiUrl(apiUrl);

      generateSearchParams(extraApiParams, searchParams, validPageNumber);

      if (currentPageNumberIsPresentInNext || validPageNumber === 1) {
        const response = await axios.get(validApiUrl, { params: searchParams });
        const data = response.data;

        if (data.length < PAGINATION_SIZE) {
          return { data: data, validPageNumber: null };
        }
        return { data, validPageNumber };
      } else {
        return { data: [], validPageNumber: null };
      }
    } catch (err) {
      console.error(err);
      if (err.response?.data?.msg) {
        return thunkAPI.rejectWithValue(err.response.data);
      } else if (err.message) {
        return thunkAPI.rejectWithValue({ msg: err.message });
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchARecord = createAsyncThunk(
  "rest/all/fetch",
  async ({ apiUrl, id }) => {
    console.log({ apiUrl, id });
    const response = await axios.get(apiUrl + `/${id}/`);
    const data = response.data;
    return data;
  }
);

export const deleteARecord = createAsyncThunk(
  "rest/all/delete",
  async ({ apiUrl, id }, thunkAPI) => {
    console.log({ apiUrl, id });
    try {
      const response = await axios.delete(apiUrl + `/${id}/`);
      const data = response.data;
      return data;
    } catch (err) {
      if (err?.response?.data) {
        if (err.response.data.responseMsg) {
          let msg = responseCodeMessagesEnum[err.response.data.responseMsg];
          let data = {
            responseMsg: msg,
          };
          return thunkAPI.rejectWithValue(data);
        }
        return thunkAPI.rejectWithValue({});
      }
      return thunkAPI.rejectWithValue({});
    }
  }
);

export const addARecord = createAsyncThunk(
  "rest/all/add",
  async ({ apiUrl, data, headers }, thunkAPI) => {
    // const fullState = thunkAPI.getState();
    // data['created_by'] = fullState.auth?.user?.id;
    // data['updated_by'] = fullState.auth?.user?.id;
    console.log({ apiUrl, data, headers });
    try {
      let response;
      if (headers) {
        if ("Content-Type" in headers) {
          response = await axios.post(apiUrl + "/", data, { headers });
        }
      } else {
        response = await axios.post(apiUrl + "/", data);
      }

      const responseData = response.data;
      return responseData;
    } catch (err) {
      if (err?.response?.data) {
        if (err.response.data.responseMsg) {
          let msg = responseCodeMessagesEnum[err.response.data.responseMsg];
          let data = {
            responseMsg: msg,
          };
          return thunkAPI.rejectWithValue(data);
        }
        return thunkAPI.rejectWithValue({});
      }
      return thunkAPI.rejectWithValue({});
    }
  }
);

export const editARecord = createAsyncThunk(
  "rest/all/edit",
  async ({ apiUrl, id, data, headers }, thunkAPI) => {
    console.log({ apiUrl, data, headers });
    try {
      let response;
      if (headers) {
        if ("Content-Type" in headers) {
          response = await axios.patch(apiUrl + `/${id}/`, data, { headers });
        }
      } else {
        response = await axios.patch(apiUrl + `/${id}/`, data);
      }
      const responseData = response.data;
      return responseData;
    } catch (err) {
      if (err?.response?.data) {
        if (err.response.data.responseMsg) {
          let msg = responseCodeMessagesEnum[err.response.data.responseMsg];
          let data = { responseMsg: msg };
          return thunkAPI.rejectWithValue(data);
        }
        return thunkAPI.rejectWithValue({});
      }
      return thunkAPI.rejectWithValue({});
    }
  }
);

export const fetchAllDocumentTypes = async () => {
  try {
    const response = await axios.get("/document/rest/documenttypes");
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const fetchAllServices = async () => {
  try {
    const response = await axios.get("/plan/rest/services");
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const getServiceDocumentTypes = async (serviceId) => {
  try {
    const response = await axios.get(
      `/plan/rest/servicedocumenttypes?service__id=${serviceId}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const getDocumentTypes = async () => {
  try {
    const response = await axios.get(`/document/rest/documenttypes/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const createServiceDocumentTypes = async (data) => {
  try {
    const response = await axios.post(
      `/plan/rest/servicedocumenttypes/choose_service_document_type/`,
      data
    );
    if (response.status === 201) {
      return response;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const getSession = async (id) => {
  try {
    const response = await axios.get(`/session/rest/session/${id}/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const updateSessionDocuments = async (data) => {
  try {
    const response = await axios.post(
      `/session/rest/sessiondocument/update_session_documents/`,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const fetchAllUsers = async () => {
  try {
    const response = await axios.get(`/user/rest/users/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const fetchAllPlans = async () => {
  try {
    const response = await axios.get(`/plan/rest/plans/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

const initialState = {
  data: [],
  next: null,
  previous: null,
  currentPageNumber: 1,
  count: 0,
  fetchRequestStatus: null,
  fetchRequestErrorMsg: null,
  deleteRecordStatus: null,
  deleteRecordErrorMsg: null,
  addRecordStatus: null,
  addRecordErrorMsg: null,
  editRecordStatus: null,
  editRecordErrorMsg: null,
  fetchRecordStatus: null,
  currentEditViewFormId: null,
  currentFormMode: FormModesEnum.NONE,
  currentRecordData: {},
};

const masterSlice = createSlice({
  name: "master",
  initialState: initialState,
  reducers: {
    incrementPageNumber: (state, action) => {
      if (state.data.length < state.count) {
        state.currentPageNumber = state.currentPageNumber + 1;
      }
    },
    resetMasterSlice: (state, action) => {
      return initialState;
    },
    resetDeleteRecordStatus(state) {
      state.deleteRecordStatus = null;
      state.deleteRecordErrorMsg = null;
    },
    resetFetchRequestStatus(state) {
      state.fetchRequestStatus = null;
    },
    resetAddRecordStatus(state) {
      state.addRecordStatus = null;
      state.addRecordErrorMsg = null;
    },
    resetEditRecordStatus(state) {
      state.editRecordStatus = null;
      state.editRecordErrorMsg = null;
    },
    resetFetchRecordStatus(state) {
      state.fetchRecordStatus = null;
    },
    setFormModeAsEdit(state, action) {
      state.currentEditViewFormId = action.payload.id;
      state.currentFormMode = FormModesEnum.EDIT;
    },
    setFormModeAsAdd(state) {
      state.currentEditViewFormId = null;
      state.currentFormMode = FormModesEnum.ADD;
    },
    setFormModeAsView(state, action) {
      state.currentEditViewFormId = action.payload.id;
      state.currentFormMode = FormModesEnum.VIEW;
    },
    resetFormModeToNone(state) {
      state.currentEditViewFormId = null;
      state.currentFormMode = FormModesEnum.NONE;
      state.currentRecordData = {};
      state.fetchRecordStatus = null;
    },
    setCurrentRecordData: (state, action) => {
      state.currentRecordData = action.payload;
    },
    setCurrentPageNumber: (state, action) => {
      state.currentPageNumber = action.payload;
    }
  },
  extraReducers: (builder) => {
    // fetch all records
    builder.addCase(fetchAllRecords.pending, (state) => {
      state.fetchRequestStatus = asyncStatuses.LOADING;
    });
    builder.addCase(fetchAllRecords.fulfilled, (state, action) => {
      state.fetchRequestStatus = asyncStatuses.SUCCESS;
      let data = action.payload.data.results;
      let pageNumber = action.payload.validPageNumber;

      if (Array.isArray(data)) {
        data = data.map((d, i) => {
          d.key = i + "" + pageNumber;
          return d;
        });
      }

      console.log({ data });

      // if (pageNumber && pageNumber !== 1) {
      //   let alreadyPresentData = JSON.parse(JSON.stringify(state.data));
      //   data.forEach?.((d) => alreadyPresentData.push(d));
      //   data = alreadyPresentData;
      // }]

      // data = [...data];


      console.log({ pageNumber, data });

      if (pageNumber) {
        state.data = data;
        state.next = action.payload.data.next;
        state.count = action.payload.data.count;
        state.previous = action.payload.data.previous;
      }
    });
    builder.addCase(fetchAllRecords.rejected, (state, action) => {
      state.fetchRequestStatus = asyncStatuses.FAILED;
      if (action.payload?.msg) {
        state.fetchRequestErrorMsg = action.payload.msg;
      }
      state.data = [];
      state.next = null;
      state.count = 0;
      state.previous = null;
      state.currentPageNumber = 1;
    });

    // delete a record
    builder.addCase(deleteARecord.pending, (state) => {
      state.deleteRecordStatus = asyncStatuses.LOADING;
    });
    builder.addCase(deleteARecord.fulfilled, (state) => {
      state.deleteRecordStatus = asyncStatuses.SUCCESS;
    });
    builder.addCase(deleteARecord.rejected, (state, action) => {
      console.log("Delete a record failed");
      state.deleteRecordStatus = asyncStatuses.FAILED;
      if (action.payload) {
        if ("responseMsg" in action.payload) {
          state.deleteRecordErrorMsg = action.payload.responseMsg;
        }
      }
    });

    // add a record
    builder.addCase(addARecord.pending, (state) => {
      state.addRecordStatus = asyncStatuses.LOADING;
    });
    builder.addCase(addARecord.fulfilled, (state) => {
      state.addRecordStatus = asyncStatuses.SUCCESS;
    });
    builder.addCase(addARecord.rejected, (state, action) => {
      state.addRecordStatus = asyncStatuses.FAILED;
      if (action.payload) {
        if ("responseMsg" in action.payload) {
          state.addRecordErrorMsg = action.payload.responseMsg;
        }
      }
    });

    // fetch a record
    builder.addCase(fetchARecord.pending, (state) => {
      state.fetchRecordStatus = asyncStatuses.LOADING;
      state.currentRecordData = {};
    });
    builder.addCase(fetchARecord.fulfilled, (state, action) => {
      state.fetchRecordStatus = asyncStatuses.SUCCESS;
      console.log(action);
      state.currentRecordData = action.payload;
    });
    builder.addCase(fetchARecord.rejected, (state) => {
      state.fetchRecordStatus = asyncStatuses.FAILED;
      state.currentRecordData = {};
    });

    // edit a record
    builder.addCase(editARecord.pending, (state) => {
      state.editRecordStatus = asyncStatuses.LOADING;
    });
    builder.addCase(editARecord.fulfilled, (state) => {
      state.editRecordStatus = asyncStatuses.SUCCESS;
    });
    builder.addCase(editARecord.rejected, (state, action) => {
      state.editRecordStatus = asyncStatuses.FAILED;
      if (action.payload) {
        if ("responseMsg" in action.payload) {
          state.deleteRecordErrorMsg = action.payload.responseMsg;
        }
      }
    });

    // builder.addCase(REHYDRATE, (state, action) => {
    //     // Reset resettableData after rehydration
    //     console.log({ rese_state: state })
    //     state.auth.status = state.auth.status === asyncStatuses.LOADING ? null : state.auth.status;
    // })
  },
});

export const {
  clearData,
  incrementPageNumber,
  setCurrentPageNumber,
  resetDeleteRecordStatus,
  resetFetchRequestStatus,
  resetFetchRecordStatus,
  resetAddRecordStatus,
  resetEditRecordStatus,
  setFormModeAsAdd,
  setFormModeAsEdit,
  setFormModeAsView,
  resetFormModeToNone,
  resetMasterSlice,
  setCurrentRecordData,
} = masterSlice.actions;

export default masterSlice.reducer;
