import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, InputNumber, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import axios from 'axios';
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [builders, setBuilders] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState(null);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isArchivedEnabled, setIsArchivedEnabled] = useState(false);

  const propertySegments = [
    { id: 1, label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)", value: "AFFORDABLE_RESIDENTIAL" },
    { id: 2, label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)", value: "MID_RANGE_RESIDENTIAL" },
    { id: 3, label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)", value: "LUXURY_RESIDENTIAL" },
    { id: 4, label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)", value: "ULTRA_LUXURY_RESIDENTIAL" },
    { id: 5, label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)", value: "BASIC_COMMERCIAL" },
    { id: 6, label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)", value: "MID_RANGE_COMMERCIAL" },
    { id: 7, label: "High-End Commercial (₹4,000 per sq. ft. and above)", value: "HIGH_END_COMMERCIAL" },
  ];

  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 6, layout: "5BHK" }];

  const fetchBuilders = async () => {
    try {
      const response = await axios.get("/user/rest/builders/");
      console.log(response);
      setBuilders(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const fetchProjectTypes = async () => {
    try {
      const response = await axios.get("/project/project-types/");
      console.log(response);
      setProjectTypes(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    fetchBuilders();
    fetchProjectTypes();
  }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
      setImage(null);
      setIsArchivedEnabled(false);
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
      setImage(null);
      setIsArchivedEnabled(false);
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);

    if (currentRecordData?.property_img) {
      setInitialImage(currentRecordData.property_img);
    }

    // if (currentRecordData?.media_files) {
    //   setMediaFiles(currentRecordData.media_files);
    // }

    if (currentRecordData?.media_files) {
      setFileList(
        currentRecordData.media_files.map((file, index) => ({
          name: file.temp_file_name,
          uid: index,
          id: file.id,
          url: file.file,
        }))
      );
    }

    if (currentRecordData?.archive_request) {
      setIsArchivedEnabled(true);
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (event) => {
    console.log(event);
    const file = event.target.files[0];
    console.log({ file });

    if (file) {
      setImage(file); // Store the selected file
    } else {
      setImage(null);
    }
  };

  // const handleFileChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   // Filter valid files based on the file extension
  //   const validFiles = files.filter(file => {
  //     const fileExtension = file.name.split('.').pop().toLowerCase();
  //     return ['zip', 'jpg', 'jpeg', 'png', 'dwg', 'skp'].includes(fileExtension);
  //   });

  //   // Check if mediaFiles is defined and is an array
  //   if (!Array.isArray(mediaFiles)) {
  //     console.warn("mediaFiles is not an array or is undefined. Initializing it to an empty array.");
  //     setMediaFiles([]); // Initialize mediaFiles to an empty array if it's not defined
  //   }

  //   // Filter out files that already exist based on existing attributes (name, size, lastModified, extension)
  //   const updatedFiles = validFiles.filter(newFile => {
  //     return !mediaFiles.some(existingFile => {
  //       // For new files, we won't have the 'extension' attribute yet, but for existing files, we will compare extensions.
  //       const fileExtension = newFile.name.split('.').pop().toLowerCase();
  //       return (
  //         existingFile.name === newFile.name &&
  //         existingFile.size === newFile.size &&
  //         existingFile.lastModified === newFile.lastModified &&
  //         existingFile.extension === fileExtension // Compare extension for existing files
  //       );
  //     });
  //   });

  //   // Create file objects with status (isNew = true for new files)
  //   const filesWithStatus = updatedFiles.map(file => ({
  //     file,
  //     isNew: true, // Mark new files
  //     isUpdated: false, // Files are new, so they are not updated yet
  //     extension: file.name.split('.').pop().toLowerCase(), // Extract extension for new files
  //   }));

  //   // If there are new or updated files, add them to the mediaFiles state
  //   if (filesWithStatus.length > 0) {
  //     setMediaFiles(prevFiles => [...prevFiles, ...filesWithStatus]);
  //   } else {
  //     // Handle case where no new or updated files were added (optional)
  //     console.log("No new valid files to add.");
  //   }

  //   // Optional: Show an error message if no valid files are selected
  //   if (validFiles.length === 0) {
  //     console.log("Please select valid files.");
  //   }
  // };

  // const handleDeleteFile = (index) => {
  //   const updatedFiles = mediaFiles.filter((_, i) => i !== index);
  //   setMediaFiles(updatedFiles);
  // };

  // const handleReplaceFile = (event, index) => {
  //   const newFile = event.target.files[0];
  //   if (newFile) {
  //     const updatedFiles = [...mediaFiles];
  //     updatedFiles[index] = {
  //       ...updatedFiles[index],
  //       file: newFile,
  //       name: newFile.name,
  //       url: URL.createObjectURL(newFile),
  //       extension: newFile.name.split('.').pop().toLowerCase(),
  //       isUpdated: true
  //     };
  //     setMediaFiles(updatedFiles); // Update the state
  //   }
  // };

  const handleUploadChange = ({ file, fileList: updatedFileList }) => {

    if (!(file instanceof File)) {
      return false; // Prevent further processing
    }
    // Update state with the new file list
    setFileList(updatedFileList);
    console.log(file);

    // Validate file extensions if required
    const validExtensions = ['zip', 'jpg', 'jpeg', 'png', 'dwg', 'skp'];
    if (!validExtensions.includes(file.name.split('.')?.pop().toLowerCase())) {
      openNotification("Invalid File", "Please upload a valid file type.", "error");
    }
  };

  const handleRemoveFile = (file) => {
    // Remove file from the state
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const handleBeforeUpload = (file) => {
    // Define allowed file extensions
    const validExtensions = ['zip', 'jpg', 'jpeg', 'png', 'dwg', 'skp'];

    // Get the file extension and validate
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isValid = validExtensions.includes(fileExtension);

    if (!isValid) {
      openNotification("Invalid File", "Please upload a valid file type.", "error");
      return false; // Prevent invalid files from being added
    }

    // Return false to prevent immediate upload
    return false;
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({
      apiUrl: apiUrl, data: val, headers: {
        "Content-Type": "multipart/form-data",
      }
    }));
  };

  const edit = (val) => {

    dispatch(editARecord({
      apiUrl, id: currentEditViewFormId, data: val, headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
  };

  const onFinish = (val) => {
    // delete val.confirm_password;
    val.username = val.email;

    if (image) {
      val.property_img = image;
    }

    // Map media files into the correct format
    const formattedMediaFiles = fileList.map((file) => {
      // Check if it's an existing file (by checking if it has an `id`)
      if (file.id) {
        return {
          id: file.id,    // Existing file ID
          url: file.url,
          name: file.name   // Existing file URL
        };
      }
      // If it's a new file, include the `originFileObj` (the actual file data)
      return {
        file: file.originFileObj,  // New file
      };
    });

    // Add the formatted media files to the val object
    val.media_files = formattedMediaFiles;

    if (val.archive_request) {
      val.is_archived = val.is_archived || false;
    }
    console.log({ val });


    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      // form.resetFields();  // Reset form after adding
      // setImage(null);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      // form.resetFields();  // Reset form after adding
      // setImage(null);
    } else {
      console.log("No suitable mode found");
    }
  };

  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          name="builder"
          label="Builder"
          rules={[{ required: true, message: 'Please select a builder!' }]}
        >
          <Select placeholder="Select a builder">
            {builders.map(builder => (
              <Option key={builder.id} value={builder.id}>{builder.company_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="property_name"
          label="Property Name"
          rules={[{ required: true, message: 'Please enter the property name!' }]}
        >
          <Input placeholder="Enter property name" />
        </Form.Item>

        <Form.Item
          name="flats"
          label="Number of Flats"
          rules={[{ required: true, message: 'Please enter the number of flats!' }]}
        >
          <InputNumber min={0} placeholder="Enter number of flats" />
        </Form.Item>

        <Form.Item
          name="property_year"
          label="Property Year"
          rules={[{ required: true, message: 'Please enter the year the property was made!' }]}
        >
          <InputNumber min={0} placeholder="Enter built year of property" />
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
          rules={[{ required: true, message: 'Please enter the location!' }]}
        >
          <Input placeholder="Enter location" />
        </Form.Item>

        <Form.Item
          name="project_type"
          label="Project Type"
          rules={[{ required: true, message: 'Please select a project type!' }]}
        >
          <Select placeholder="Select a project type">
            {projectTypes.map(type => (
              <Option key={type.id} value={type.id}>{type.title}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="construction_status"
          label="Construction Status"
          rules={[{ required: true, message: 'Please select the construction status!' }]}
        >
          <Select placeholder="Select a construction status">
            <Option value="UNDER_CONSTRUCTION">Under Construction</Option>
            <Option value="COMPLETED">Completed</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="property_segment"
          label="Property Segment"
          rules={[{ required: true, message: 'Please select a builder!' }]}
        >
          <Select placeholder="Select a Segment">
            {propertySegments?.map(segment => (
              <Option key={segment.id} value={segment.value}>{segment.label}</Option>
            ))}
          </Select>
        </Form.Item>


        <Form.Item
          label="Property Image"
          rules={[]}
        >
          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No image available</p>
          )}

          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && <input
            type="file"
            accept="image/*"
            id="imageUpload"
            onChange={handleImageChange}
          />}
        </Form.Item>

        {/* <Form.Item label="Media Files">
          <div>
            {Array.isArray(mediaFiles) && mediaFiles.length > 0 ? (
              mediaFiles?.map((file, index) => (
                <div key={index} className="media-file-item" style={{ marginBottom: '10px' }}>
                  <span style={{ display: 'block', marginBottom: '5px' }}>{file.temp_file_name}</span>

                  {['.jpg', '.jpeg', '.png'].includes(file.extension.toLowerCase()) ? (
                    <img
                      src={file.file}
                      alt={file.temp_file_name}
                      style={{
                        width: '100px',
                        height: '100px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        marginBottom: '5px',
                      }}
                    />
                  ) : file.extension.toLowerCase() === '.pdf' ? (
                    <a href={file.file} target="_blank" rel="noopener noreferrer">
                      View PDF
                    </a>
                  ) : (
                    <a href={file.file} download>
                      Download File
                    </a>
                  )}


                  {(currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.ADD) && (
                    <Button type="link" onClick={() => handleDeleteFile(index)} style={{ marginLeft: '10px' }}>
                      Delete
                    </Button>
                  )}


                  {(currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.ADD) && (
                    <div style={{ marginTop: '10px' }}>
                      <input
                        type="file"
                        accept=".zip,.jpg,.jpeg,.png,.dwg,.skp,.pdf"
                        onChange={(event) => handleReplaceFile(event, index)}
                      />
                    </div>
                  )}
                </div>
              ))
            ) : (
              (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.ADD) && (
                <div style={{ marginTop: '10px' }}>
                  <input
                    type="file"
                    multiple
                    accept=".zip,.jpg,.jpeg,.png,.dwg,.skp,.pdf"
                    onChange={handleFileChange}
                  />
                </div>
              )
            )}

          </div>
        </Form.Item> */}
        <Form.Item name="media_files" label="Media Files">
          <Upload
            listType="picture"
            fileList={fileList}
            onChange={handleUploadChange}
            onRemove={handleRemoveFile}
            beforeUpload={handleBeforeUpload}
            multiple
            showUploadList={{
              itemRender: (originNode, file, fileList, actions) => (
                <span>{file.name}</span>
              )
            }}
          >
            <Button icon={<UploadOutlined />}>Upload Files</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="new_property"
          label="Is New Property?"
        >
          <Select placeholder="Select">
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="recommended"
          label="Is Recommended?"
        >
          <Select placeholder="Select">
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="archive_request"
          label="Archive requested?"
        >
          <Select placeholder="Select" disabled>
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="is_archived"
          label="Is Archived?"
          rules={[{ required: isArchivedEnabled }]}
        >
          <Select placeholder="Select" disabled={!isArchivedEnabled}>
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="status"
          label="Approval Status"
          rules={[{ required: true, message: 'Please select the approval status!' }]}
        >
          <Select placeholder="Select a status">
            <Option value="UNDER_APPROVAL">Under Approval</Option>
            <Option value="APPROVED">Approved</Option>
            <Option value="REJECTED">Rejected</Option>
          </Select>
        </Form.Item>

        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm; 