import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const AddEditForm = ({ apiUrl, isOpen }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [email, setEmail] = useState(null);


  const [initialImage, setInitialImage] = useState(null);


  const fetchUsers = async () => {
    try {
      const response = await axios.get("/user/rest/users/");
      console.log(response);
      setUserOptions(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching users list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
      setImage(null);
      setInitialImage(null);
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
      setImage(null);
      setInitialImage(null);
    } else if (!isOpen) {
      form.resetFields();
      setImage(null);
      setInitialImage(null);
    }
  }, [editRecordStatus, addRecordStatus, form, isOpen]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);

    if (currentRecordData?.image) {
      setInitialImage(currentRecordData.image);
    }

    if(currentRecordData?.user_data?.email){
      setEmail(currentRecordData?.user_data.email)
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;

    if (image) {
      val.image = image;
    }
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      form.resetFields();
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        {/* <Form.Item
          label="Testimonial For"
          name="testimonial_for"
          rules={[{ required: true, message: "Please select a user!" }]}
        >
          <Select placeholder="Select user">
            {userOptions.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.full_name || user.company_name} - {user.user_type}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input.TextArea placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          label="Testimonial"
          name="testimonial"
          rules={[{ required: true, message: "Please input the testimonial!" }]}
        >
          <Input.TextArea placeholder="Enter testimonial" />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name={email}
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          label="Image"
          rules={[{ required: true, message: "Please upload an image!" }]}
        >
          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (
            <p>No image available</p>
          )}
          {(currentFormMode === FormModesEnum.ADD||currentFormMode===FormModesEnum.EDIT)&&<input
            type="file"
            id="imageUpload"
            required={currentFormMode === FormModesEnum.ADD}
            onChange={handleImageChange}
          />}
        </Form.Item>
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
