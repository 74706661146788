import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;

const TYPE_OF_BUSINESS_CHOICES = [
  // { value: 'ADH', label: 'Adhesives' },
  // { value: 'WF', label: 'Wood and Vinyl Flooring' },
  // { value: 'TEW', label: 'Natural, Teak, Engineered Wood' },
  // { value: 'HCP', label: 'HPL, Compacts, Panels, Wall Cladding, etc' },
  { value: "laminates", label: "Laminates" },
  { value: "plywoods", label: "Plywoods" },
  { value: "outdoor_furniture", label: "Outdoor Furniture" },
  { value: "bathroom_fitting", label: "Bathroom Fitting" },
  { value: "veneers", label: "Veneers" },
  { value: "decorative", label: "Decoratives" },
  { value: "wallpapers", label: "Wallpapers" },
  { value: "adhesives", label: "Adhesives" },
  { value: "wooden_flooring", label: "Wooden Flooring" },
  { value: "ceramic_tiles", label: "Ceramic Files" },
  { value: "marbles", label: "Marbles" },
  { value: "lightitng_fixtures", label: "Lighting Fixtures" },
  { value: "kitchen_modules", label: "Kitchen Modules" },
  { value: "hardwares", label: "Hardwares" },
];

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [checked, setChecked] = useState(true);

  const brandTypeChoices = [
    { id: 1, label: "Timbers, Plywood, and Laminates", value: "timbers_plywood_and_laminates" },
    { id: 2, label: "Flooring", value: "flooring" },
    { id: 3, label: "Others", value: "others" }
  ];

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  console.log(currentRecordData);


  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    val.image = image;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: false, message: "Please input the name!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          label="Brand Type"
          name="type"
          rules={[{ required: false, message: "Please input the type!" }]}
        >
          <Select placeholder="Select a brand type">
            {brandTypeChoices?.map(segment => (
              <Option key={segment.id} value={segment.value}>{segment.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="recommended"
          label="Is Recommended?"
        >
          <Select placeholder="Select">
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
