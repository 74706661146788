import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    addARecord,
    editARecord,
    fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const solutions = [
    {
        id: 1,
        title: "Turnkey (Complete) Interior Solution",
        slug: "turnkey",
        turnkey: true,
    },
    {
        id: 2,
        title: "Project Drawings Only",
        slug: "customised",
        description: "Final construction drawings and architect support till release of final drawings. Project execution not included.",
        turnkey: false,
    }
];
const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [userData, setUserData] = useState(null);



    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top",
        });
    };

    const currentFormMode = useSelector((store) => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(
        (store) => store.master?.currentEditViewFormId
    );
    const fetchRecordStatus = useSelector(
        (store) => store.master?.fetchRecordStatus
    );
    const editRecordStatus = useSelector(
        (store) => store.master?.editRecordStatus
    );
    const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
    const currentRecordData = useSelector(
        (store) => store.master?.currentRecordData
    );

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);

        if (currentRecordData?.user_data) {
            setUserData(currentRecordData.user_data);

            form.setFieldsValue({
                full_name: currentRecordData.user_data?.full_name,
                email: currentRecordData.user_data?.email,
                mobile: currentRecordData.user_data?.mobile,
            });
        }

    }, [fetchRecordStatus, currentRecordData, form]);

    console.log({ userData });



    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (
            currentEditViewFormId &&
            (currentFormMode === FormModesEnum.EDIT ||
                currentFormMode === FormModesEnum.VIEW)
        ) {
            if (!currentEditViewFormId) {
                openNotification(
                    "Something went wrong",
                    "Please contact developers. Id was not provided while changing modes",
                    "error"
                );
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    };

    const edit = (val) => {
        // console.log(val);
        dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
    };

    const onFinish = (val) => {
        delete val.confirm_password;
        val.username = val.email;
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
            form.resetFields();
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
            form.resetFields();
        } else {
            console.log("No suitable mode found");
        }
    };
    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Form.Item
                    label="Service Type"
                    name="service_type"
                    rules={[{ required: true, message: "Please select a service type!" }]}
                >
                    <Select placeholder="Select product">
                        {solutions.map((solution) => (
                            <Option key={solution.id} value={solution.slug}>
                                {solution.title}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Area"
                    name="area"
                    rules={[{ required: true, message: "Please input area" }]}
                >
                    <Input placeholder="Enter your area" />
                </Form.Item>

                <Form.Item
                    label="Project Type"
                    name="project_type"
                    rules={[{ required: true, message: "Please input area" }]}
                >
                    <Input placeholder="Enter your area"  disabled/>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="full_name"
                    rules={[{ required: true, message: "Please input your full name!" }]}
                >
                    <Input placeholder="Enter your full name" />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Please input a valid email!",
                        },
                    ]}
                >
                    <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            type: "mobile",
                            message: "Please input a valid mobile number!",
                        },
                    ]}
                >
                    <Input placeholder="Enter your mobile" />
                </Form.Item>
                <Form.Item
                    label="Requirement Message"
                    name="requirement_msg"
                    rules={[
                        {
                            required: true,
                            type: "textarea",
                            message: "Please input a valid requirement message!",
                        },
                    ]}
                >
                    <Input placeholder="Enter your requirement message" />
                </Form.Item>
                {currentFormMode === FormModesEnum.ADD ? (
                    <Form.Item>
                        {addRecordStatus === asyncStatuses.LOADING ? (
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Submitting
                            </Button>
                        ) : (
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        )}
                    </Form.Item>
                ) : currentFormMode === FormModesEnum.EDIT ? (
                    <Form.Item>
                        {editRecordStatus === asyncStatuses.LOADING ? (
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Updating
                            </Button>
                        ) : (
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        )}
                    </Form.Item>
                ) : (
                    <></>
                )}
            </Form>
        </>
    );
};
export default AddEditForm;
