import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);

    if (currentRecordData?.image) {
      setInitialImage(currentRecordData.image);
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({
      apiUrl: apiUrl, data: val, headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({
      apiUrl, id: currentEditViewFormId, data: val, headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;

    if (image) {
      val.image = image;
    }
    val.image = image;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        {/* <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: 'Please select a title!' }]}
      >
        <Select placeholder="Select project type">
          {TITLE_CHOICES.map(choice => (
            <Option key={choice.value} value={choice.value}>{choice.label}</Option>
          ))}
        </Select>
      </Form.Item> */}
        <Form.Item
          label="Title"
          name="title"
          rules={[
            { required: true, message: 'Please input the title!' },
          ]}
        >
          <Input placeholder="Please enter a type" />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
        >
          <Input placeholder="Please enter a slug or keep empty to generate one" />
        </Form.Item>
        <Form.Item
          label="Image"
          rules={[]}
        >
          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No image available</p>
          )}
          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && <input
            type="file"
            id="imageUpload"
            // required={currentFormMode === FormModesEnum.ADD}
            onChange={handleImageChange}
          />}
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;