import { Pagination } from 'antd';
import { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';

const CustomPagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const totalDataCount = useSelector(store => store.master.count);

    const onPageChange = useCallback((pageNumber) => {
        // console.log(pageNumber);
        setSearchParams(params => {
            params.set(searchParamsEnum.PAGE, pageNumber);
            return params;
        });
    }, [setSearchParams]);

    useLayoutEffect(() => {
        let searchParamPage = searchParams.get('page');
        if (!searchParamPage) {
            onPageChange(1);
        }
    },[onPageChange, searchParams]);

    if (totalDataCount) {
        return (
            <>
                <Pagination
                    style={{ padding: "20px" }}
                    defaultCurrent={searchParams.get(searchParamsEnum.PAGE)}
                    current={searchParams.get(searchParamsEnum.PAGE)}
                    total={totalDataCount}
                    showSizeChanger={false}
                    pageSize={12}
                    onChange={onPageChange}
                />
            </>
        );
    } else {
        return <></>;
    }

};
export default CustomPagination;