import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import ProductCategoryAddEditForm from "Components/Forms/Master/ProductCategory/ProductCategoryAddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Button, Flex, Form, Image, Modal, notification, Upload } from "antd";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons"


const apiUrl = "/product/product-category";
const headerTitle = "Product Category Master";
const editPermission = "productcategories:edit";
const deletePermission = "productcategories:delete";
const addPermission = "productcategories:add";
const columns = [
    {
        title: 'Type',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        render: (text) => <Image src={text} width={70} height="auto" />,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const ProductCategoryPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const fileBeforeUpload = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return false;
    };

    const onFinish = async (val) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', val['file']['fileList'][0]['originFileObj']);
        try {
            const response = await axios.post(`/product/import-product-category/`, formData);
            if (response.status === 201) {
                notification.success("File uploaded successfully!");
            }
        } catch (e) {
            notification.error(e.message);
            // console.log("Could not upload the file!");
        }
        setIsModalOpen(false);
        setIsUploading(false);
        form.resetFields();
    }

    return (
        <>
            <Flex
                justify="end"
            >
                <Button onClick={() => setIsModalOpen(true)}>Mass Upload</Button>
            </Flex>
            <CustomTable
                columns={_columns}
                apiUrl={apiUrl}
                headerTitle={headerTitle}
                MyFormComponent={ProductCategoryAddEditForm}
                addPermissionNames={[addPermission]}
            />
            <Modal
                title="Mass Upload Product Categories"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={[]}
            >
                <Form
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item name="file" label=".xlsx file">
                        <Upload
                            beforeUpload={fileBeforeUpload}
                            accept=".xlsx"
                            name="file"
                            multiple={false}
                            showUploadList={true}
                        >
                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                        </Upload>
                    </Form.Item>
                    {isUploading ?
                        <Button type="primary" htmlType="submit" disabled>
                            <LoadingOutlined />
                            Uploading
                        </Button>
                        :
                        <Button type="primary" htmlType="submit" >
                            Upload
                        </Button>
                    }
                </Form>
            </Modal>
        </>
    );
}

export default ProductCategoryPage;