import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons";
import axios from 'axios';
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
    form.setFieldValue("email", currentRecordData?.user_data?.email );

    if (currentRecordData?.user_data) {
      setInitialImage(currentRecordData?.user_data.profile_picture);
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({
      apiUrl: apiUrl, data: val, headers: {
        "Content-Type": "multipart/form-data",
      }
    }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({
      apiUrl, id: currentEditViewFormId, data: val, headers: {
        "Content-Type": "multipart/form-data",
      }
    }));
  };

  const onFinish = (val) => {
    // delete val.confirm_password;
    val.username = val.email;

    if (image) {
      val.user_data.profile_picture = image;
    }

    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();  // Reset form after adding
      setImage(null);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      form.resetFields();  // Reset form after adding
      setImage(null);
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name="company_name"
          rules={[{ required: true, message: 'Please input your company name!' }]}
        >
          <Input placeholder="Enter your company name" />
        </Form.Item>

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: 'Please input your location!' }]}
        >
          <Input placeholder="Enter your location" />
        </Form.Item>

        <Form.Item
          label="Project Count"
          name="project_count"
          rules={[{ required: true, message: 'Please input the project count!' }]}
        >
          <InputNumber min={0} placeholder="Enter project count" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Years of Experience"
          name="years_of_experience"
          rules={[{ required: true, message: 'Please input your years of experience!' }]}
        >
          <InputNumber min={0} placeholder="Enter years of experience" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Number of Cities"
          name="no_of_cities"
          rules={[{ required: true, message: 'Please input the number of cities!' }]}
        >
          <InputNumber min={0} placeholder="Enter number of cities" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Rating"
          name="rating"
          rules={[{ required: true, message: 'Please input the rating!' }]}
        >
          <InputNumber min={0} max={5} step={0.01} placeholder="Enter rating (0-5)" />
        </Form.Item>

        <Form.Item
          label="Expertise"
          name="expertise"
          rules={[{ required: true, message: 'Please input expertise!' }]}
        >
          <Input placeholder="Enter expertise" />
        </Form.Item>

        <Form.Item
          label="Job Cost Minimum"
          name="job_cost_minimum"
          rules={[{ required: true, message: 'Please input the minimum job cost!' }]}
        >
          <InputNumber
            min={0}
            step={100000}
            placeholder="Enter minimum job cost"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          label="Job Cost Maximum"
          name="job_cost_maximum"
          rules={[{ required: true, message: 'Please input the maximum job cost!' }]}
        >
          <InputNumber
            min={0}
            step={100000}
            placeholder="Enter maximum job cost"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Profile Picture"
          rules={[]}
        >
          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No image available</p>
          )}

          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && <input
            type="file"
            accept="image/*"
            id="imageUpload"
            onChange={handleImageChange}
          />}
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;