import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Marketing/NewsletterList/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/marketing/send-pdf/";
const headerTitle = "Email List";
// const editPermission = "reviewratings:edit";
// const deletePermission = "reviewratings:delete";
// const addPermission = "reviewratings:add"
const columns = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    ...getCommonColumns({ apiUrl, created: true, updated: true, action: true })
]

const NewsLetterList = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        // addPermissionNames={[addPermission]}
    />;
}

export default NewsLetterList;