import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import DashboardLayout from "../Components/Layouts/Dashboard";

import PageNotFound from "Pages/PageNotFound";
import UsersPage from "Pages/PlatformUsers/UsersPage";
import DashboardPage from "Pages/Dashboard/DashboardPage";
import RolesPage from "Pages/PlatformUsers/RolesPage";
import PermissionsPage from "Pages/PlatformUsers/PermissionsPage";
import { useSelector } from "react-redux";
import NoAccess from "./Access/NoAccess";
import AccessControl from "./Access/AccessControl";
import HomePage from "Pages/HomePage";
import FailurePage from "Pages/Payments/FailurePage";
import SuccessPage from "Pages/Payments/SuccessPage";
import ProductCategoryPage from "Pages/Master/ProductCategoryPage";
import ProductTypePage from "Pages/Master/ProductTypePage";
import BrandsPage from "Pages/Brands/BrandsPage";
import ProductsPage from "Pages/Master/ProductsPage";
import ProjectTypesPage from "Pages/Master/ProjectTypesPage";
import RoomTypesPage from "Pages/Master/RoomTypesPage";
import RoomCategoryPage from "Pages/Master/RoomCategoryPage";
import TestimonialsPage from "Pages/Marketing/TestimonialsPage";
import ProductEnquiriesPage from "Pages/Marketing/ProductEnquiriesPage";
import ReviewRatingsPage from "Pages/Marketing/ReviewRatings";
import ArchitectInfoPage from "Pages/Architect/ArchitectInfoPage";
import BuilderInfoPage from "Pages/Builder/BuilderInfoPage";
import PropertiesPage from "Pages/Builder/PropertiesPage";
import PropertyBlocksPage from "Pages/Builder/PropertyBlocksPage";
import ProjectsPage from "Pages/Architect/ProjectsPage";
import RoomDetailsPage from "Pages/Architect/RoomDetails";
import OrdersPage from "Pages/Orders/OrdersPage";
import InvoicesPage from "Pages/Orders/InvoicesPage";
import PaymentsPage from "Pages/Orders/PaymentsPage";
import BrandsCategoriesPage from "Pages/Brands/BrandsCategoriesPage";
import SettingsPage from "Pages/Master/Settings/SettingsPage";
import UpdateInvoicesForm from "Components/Forms/Master/Order/UpdateInvoicesForm";
import AddInvoiceServicesForm from "Components/Forms/Master/Order/AddInvoiceServicesForm";
import InvoiceServicesPage from "Pages/Orders/InvoiceServices";
import ConsultationEnquiriesPage from "Pages/Marketing/ConsultationEnquiriesPage";
import ProductSubTypePage from "Pages/Master/ProductSubType";
import NewsLetterList from "Pages/Marketing/NewsLetterList";
import AdvertisementsPage from "Pages/Marketing/AdvertisementsPage";

const DashboardRoutes = () => {
  const user = useSelector((store) => store.auth.user);

  console.log(user);

  return (
    <DashboardLayout>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="marketing" element={<Outlet />}>
          <Route
            path="testimonials"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["testimonials:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="testimonials:view" />
                )}
              >
                <TestimonialsPage />
              </AccessControl>
            }
          />
          <Route
            path="review-ratings"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["reviewratings:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="reviewratings:view" />
                )}
              >
                <ReviewRatingsPage />
              </AccessControl>
            }
          />
          <Route
            path="news-letter-list"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["newsletter:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="newsletter:view" />
                )}
              >
                <NewsLetterList />
              </AccessControl>
            }
          />
          <Route
            path="advertisements"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["advertise:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="advertise:view" />
                )}
              >
                <AdvertisementsPage />
              </AccessControl>
            }
          />
        </Route>
        <Route path="master" element={<Outlet />}>
          <Route
            path="product-categories"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["productcategories:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="productcategories:view" />
                )}
              >
                <ProductCategoryPage />
              </AccessControl>
            }
          />
          <Route
            path="product-types"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["producttypes:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="producttypes:view" />
                )}
              >
                <ProductTypePage />
              </AccessControl>
            }
          />
          <Route
            path="product-sub-types"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["productsubtypes:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="productsubtypes:view" />
                )}
              >
                <ProductSubTypePage />
              </AccessControl>
            }
          />
          <Route
            path="products"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["product:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="product:view" />
                )}
              >
                <ProductsPage />
              </AccessControl>
            }
          />
          <Route
            path="project-types"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["projecttypes:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="projecttypes:view" />
                )}
              >
                <ProjectTypesPage />
              </AccessControl>
            }
          />
          <Route
            path="room-types"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["roomtypes:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="roomtypes:view" />
                )}
              >
                <RoomTypesPage />
              </AccessControl>
            }
          />
          <Route
            path="room-categories"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["roomcategories:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="roomcategories:view" />
                )}
              >
                <RoomCategoryPage />
              </AccessControl>
            }
          />
          {/* <Route path="languages" element={<AccessControl
              userPermissions={user.user_permissions}
              allowedPermissions={["languages:view"]}
              renderNoAccess={() => (
                  <NoAccess permissionNeeded="languages:view" />
              )}
          >
              <LanguagesPage />
          </AccessControl>} /> */}
          {/* <Route path="settings" element={<AccessControl
              userPermissions={user.user_permissions}
              allowedPermissions={["settings:view"]}
              renderNoAccess={() => (
                  <NoAccess permissionNeeded="settings:view" />
              )}
          >
              <SettingsPage />
          </AccessControl>} /> */}
        </Route>
        <Route path="brands" element={<Outlet />}>
          <Route
            path="brands"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["brands:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="brands:view" />
                )}
              >
                <BrandsPage />
              </AccessControl>
            }
          />
          <Route
            path="brand-categories"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["brandcategories:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="brandcategories:view" />
                )}
              >
                <BrandsCategoriesPage />
              </AccessControl>
            }
          />
        </Route>
        <Route path="architect" element={<Outlet />}>
          <Route
            path="architects"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["architects:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="architects:view" />
                )}
              >
                <ArchitectInfoPage />
              </AccessControl>
            }
          />
          <Route
            path="project-details"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["projectdetails:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="projectdetails:view" />
                )}
              >
                <ProjectsPage />
              </AccessControl>
            }
          />
          <Route
            path="room-details"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["roomdetails:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="roomdetails:view" />
                )}
              >
                <RoomDetailsPage />
              </AccessControl>
            }
          />
        </Route>
        <Route path="builder" element={<Outlet />}>
          <Route
            path="builders"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["builders:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="builders:view" />
                )}
              >
                <BuilderInfoPage />
              </AccessControl>
            }
          />
          <Route
            path="properties"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["properties:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="properties:view" />
                )}
              >
                <PropertiesPage />
              </AccessControl>
            }
          />
          <Route
            path="property-blocks"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["properties:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="properties:view" />
                )}
              >
                <PropertyBlocksPage />
              </AccessControl>
            }
          />
        </Route>
        <Route path="order" element={<Outlet />}>
          <Route
            path="orders"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["orders:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="orders:view" />
                )}
              >
                <OrdersPage />
              </AccessControl>
            }
          />
          <Route path=":id/update-invoices" element={<UpdateInvoicesForm />} />
          <Route
            path="payments"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["payments:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="payments:view" />
                )}
              >
                <PaymentsPage />
              </AccessControl>
            }
          />
          <Route
            path="invoices"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["invoices:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="oreder:view" />
                )}
              >
                <InvoicesPage />
              </AccessControl>
            }
          />
          <Route path="invoice/:id" element={<InvoiceServicesPage />} />
          <Route
            path="invoice/:id/add-invoice-services"
            element={<AddInvoiceServicesForm />}
          />
        </Route>
        <Route path="platform-users" element={<Outlet />}>
          <Route
            path="users"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["users:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="users:view" />
                )}
              >
                <UsersPage />
              </AccessControl>
            }
          />
          <Route
            path="roles"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["roles:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="roles:view" />
                )}
              >
                <RolesPage />
              </AccessControl>
            }
          />
          <Route
            path="permissions"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["permissions:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="permissions:view" />
                )}
              >
                <PermissionsPage />
              </AccessControl>
            }
          />
        </Route>
        <Route path="enquiry" element={<Outlet />}>
          <Route
            path="brand-enquiries"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["brandenquiries:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="brandenquiries:view" />
                )}
              >
                <PaymentsPage />
              </AccessControl>
            }
          />
          <Route
            path="product-enquiries"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["productenquiries:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="productenquiries:view" />
                )}
              >
                <ProductEnquiriesPage />
              </AccessControl>
            }
          />
          <Route
            path="consultation"
            element={
              <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["orders:view"]}
                renderNoAccess={() => (
                  <NoAccess permissionNeeded="orders:view" />
                )}
              >
                <ConsultationEnquiriesPage />
              </AccessControl>
            }
          />
        </Route>
        <Route path="payment/status" element={<Outlet />}>
          <Route path="failure" element={<FailurePage />} />
          <Route path="success" element={<SuccessPage />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </DashboardLayout>
  );
};

export default DashboardRoutes;
