import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;
const TYPE_OF_BUSINESS_CHOICES = [
  // { value: 'ADH', label: 'Adhesives' },
  // { value: 'WF', label: 'Wood and Vinyl Flooring' },
  // { value: 'TEW', label: 'Natural, Teak, Engineered Wood' },
  // { value: 'HCP', label: 'HPL, Compacts, Panels, Wall Cladding, etc' },
  { value: "laminates", label: "Laminates" },
  { value: "plywoods", label: "Plywoods" },
  { value: "outdoor_furniture", label: "Outdoor Furniture" },
  { value: "bathroom_fitting", label: "Bathroom Fitting" },
  { value: "veneers", label: "Veneers" },
  { value: "decorative", label: "Decoratives" },
  { value: "wallpapers", label: "Wallpapers" },
  { value: "adhesives", label: "Adhesives" },
  { value: "wooden_flooring", label: "Wooden Flooring" },
  { value: "ceramic_tiles", label: "Ceramic Files" },
  { value: "marbles", label: "Marbles" },
  { value: "lightitng_fixtures", label: "Lighting Fixtures" },
  { value: "kitchen_modules", label: "Kitchen Modules" },
  { value: "hardwares", label: "Hardwares" },
];

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [initialPdf, setInitialPdf] = useState();
  const [pdf, setPdf] = useState();
  const [initialImage, setInitialImage] = useState(null);
  const [brandCategories, setBrandCategories]=useState(null)


  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const fetchBrandCategories = async () => {
    try {
      const response = await axios.get("/user/rest/brand-category/");
      console.log(response);
      setBrandCategories(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  useEffect(()=>{
    fetchBrandCategories()
  },[])


  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
    form.setFieldValue("email", currentRecordData?.user_data?.email);

    if (currentRecordData?.image) {
      setInitialImage(currentRecordData.image);
    }

    if (currentRecordData?.brochure) {
      setInitialPdf(currentRecordData.brochure);
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);


  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    if (type === "image") {
      setImage(file || null);
    } else if (type === "pdf") {
      setPdf(file || null);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const onFinish = (val) => {
    // delete val.confirm_password;
    val.username = val.email;
    if (image) {
      val.image = image;  // Only add image if it's provided
    }

    if (pdf) {
      val.brochure = pdf;
    }

    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();  // Reset form after adding
      setImage(null);
      setPdf(null);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      form.resetFields();  // Reset form after adding
      setImage(null);
      setPdf(null);

    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Brand Category"
          name="brand_category"
          rules={[
            { required: true, message: "Please select the type of business!" },
          ]}
        >
          <Select placeholder="Select type of business">
            {brandCategories?.map((choice) => (
              <Option key={choice.id} value={choice.id}>
                {choice.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Warranty"
          name="warranty"
          rules={[{ required: false, message: "Please input the warranty!" }]}
        >
          <Input placeholder="Enter warranty details" />
        </Form.Item>

        <Form.Item
          label="Rating"
          name="rating"
          rules={[{ required: true, message: "Please input the rating!" }]}
        >
          <InputNumber
            min={0}
            max={5}
            step={0.01}
            placeholder="Enter rating (0-5)"
          />
        </Form.Item>

        {/* <Form.Item
          label="Average Pricing"
          name="average_pricing"
          rules={[
            { required: true, message: "Please input the average pricing!" },
          ]}
        >
          <Input placeholder="Enter average pricing" />
        </Form.Item> */}
        <Form.Item
          label="Minimum Pricing"
          name="min_pricing"
          rules={[
            { required: true, message: "Please input the Minimum pricing!" },
          ]}
        >
          <Input accept="number" placeholder="Enter minimum pricing" />
        </Form.Item>

        <Form.Item
          label="Maximum Pricing"
          name="max_pricing"
          rules={[
            { required: true, message: "Please input the maximum pricing!" },
          ]}
        >
          <Input accept="number" placeholder="Enter maximum pricing" />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name="company_name"
          rules={[
            { required: false, message: "Please input the company name!" },
          ]}
        >
          <Input placeholder="Enter company name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email!",
            },
          ]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          label="Image"
          rules={[]}
        >
          {/* <img src={} alt="" className="img-fluid" /> */}
          {image ? (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : initialImage ? (
            <img
              src={initialImage}
              alt="Initial"
              className="img-fluid mb-3"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No image available</p>
          )}
          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && <input
            type="file"
            id="imageUpload"
            // required={currentFormMode === FormModesEnum.ADD}
            onChange={(e) => handleFileChange(e, 'image')}
          />}
        </Form.Item>
        <Form.Item label="Brochure">
          {initialPdf ? (
            <a href={initialPdf} target="_blank" rel="noopener noreferrer">
              View PDF
            </a>
          ) : (currentFormMode !== FormModesEnum.ADD &&
            <p>No PDF available</p>
          )}
          {(currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.EDIT) && (
            <input type="file" accept="application/pdf" onChange={(e) => handleFileChange(e, "pdf")} />
          )}
        </Form.Item>
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
