import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const { Option } = Select;

const STATUS_CHOICES = [
  { value: "pending", label: "Pending Approval" },
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
];

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [userOptions, setUserOptions] = useState([]);

//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get("/user/rest/users/");
//       console.log(response);
//       setUserOptions(response.data.results);
//     } catch (err) {
//       console.error(err);
//       throw new Error("Error while fetching countries list");
//     }
//   };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

//   useEffect(() => {
//     fetchUsers();
//   }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      form.resetFields();
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input the review!" }]}
        >
          <Input.TextArea />
        </Form.Item>

      </Form>
    </>
  );
};
export default AddEditForm;
