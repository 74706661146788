import { Switch } from "antd";
import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Marketing/Advertisements/AddEditForm";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import getPermittedColumns from "Utils/getPermittedColumns";


const apiUrl = "/marketing/advertisements";
const headerTitle = "Advertisements";
const editPermission = "advertise:edit";
const deletePermission = "advertise:delete";
const addPermission = "advertise:add";
const columns = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Ad URL',
        dataIndex: 'url',
        key: 'url',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        render: (text) => <Image src={text} width={70} height="auto" />,
    },
    {
        title: 'Is Enabled',
        dataIndex: 'is_enabled',
        key: 'is_enabled',
        render: (text) => <Switch  checked={text} disabled/>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, action: true })
]

const AdvertisementsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions,editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default AdvertisementsPage;