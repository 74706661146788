import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, InputNumber, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons";
import axios from 'axios';
const { Option } = Select;
const { TextArea } = Input;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [architects, setArchitects] = useState([]);
  const [properties, setProperties] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [rooms, setRooms] = useState([]);

  const propertySegments = [
    { id: 1, label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)", value: "AFFORDABLE_RESIDENTIAL" },
    { id: 2, label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)", value: "MID_RANGE_RESIDENTIAL" },
    { id: 3, label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)", value: "LUXURY_RESIDENTIAL" },
    { id: 4, label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)", value: "ULTRA_LUXURY_RESIDENTIAL" },
    { id: 5, label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)", value: "BASIC_COMMERCIAL" },
    { id: 6, label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)", value: "MID_RANGE_COMMERCIAL" },
    { id: 7, label: "High-End Commercial (₹4,000 per sq. ft. and above)", value: "HIGH_END_COMMERCIAL" },
  ];

  const fetchArchitects = async () => {
    try {
      const response = await axios.get("/user/rest/architects/");
      console.log(response);
      setArchitects(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching architects list");
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get("/property/properties/");
      console.log(response);
      setProperties(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching properties list");
    }
  };

  const fetchProjectTypes = async () => {
    try {
      const response = await axios.get("/project/project-types/");
      console.log(response);
      setProjectTypes(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching project types list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    fetchArchitects();
    fetchProperties();
    // fetchBuilders();
    fetchProjectTypes();
  }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);

    if (currentRecordData?.rooms) {
      setRooms(currentRecordData.rooms);
    }
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;

    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();
    } else if (currentFormMode === FormModesEnum.EDIT) {
      delete val.builder_name;
      edit(val);
      form.resetFields();
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          name="architect"
          label="Architect"
          rules={[{ required: true, message: 'Please select an architect!' }]}
        >
          <Select placeholder="Select an architect">
            {architects.map(architect => (
              <Option key={architect.id} value={architect.id}>{architect.company_name}</Option>
            ))}
          </Select>
        </Form.Item>

        {currentFormMode !== FormModesEnum.ADD && (
          <Form.Item
            name="builder_name"
            label="Builder"
            rules={[]}
          >
            <Input type="text" disabled />
          </Form.Item>
        )}

        <Form.Item
          name="project_name"
          label="Property"
          rules={[{ required: true, message: 'Please select a property!' }]}
        >
          <Select placeholder="Select a property">
            {properties.map(property => (
              <Option key={property.id} value={property.id}>{property.property_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="project_name"
          label="Project Name"
          rules={[{ required: true, message: 'Please enter the project name!' }]}
        >
          <Input placeholder="Enter project name" />
        </Form.Item>

        <Form.Item
          name="project_type"
          label="Project Type"
          rules={[{ required: true, message: 'Please select a project type!' }]}
        >
          <Select placeholder="Select a project type">
            {projectTypes.map(type => (
              <Option key={type.id} value={type.id}>{type.title}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="property_segment"
          label="Property Segment"
          rules={[{ required: true, message: 'Please select a builder!' }]}
        >
          <Select placeholder="Select a Segment">
            {propertySegments?.map(segment => (
              <Option key={segment.id} value={segment.value}>{segment.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="project_description"
          label="Project Description"
          rules={[{ required: true, message: 'Please enter the project description!' }]}
        >
          <TextArea placeholder="Enter project description" rows={4} />
        </Form.Item>

        <Form.Item
          name="bhk"
          label="BHK"
          rules={[{ required: true, message: 'Please enter the BHK!' }]}
        >
          <Input placeholder="Enter BHK" />
        </Form.Item>

        {/* <Form.Item
          name="block"
          label="Block"
          rules={[{ required: true, message: 'Please enter the block!' }]}
        >
          <Input placeholder="Enter block" />
        </Form.Item> */}

        <Form.Item
          name="flat_area"
          label="Project Area"
          rules={[{ required: true, message: 'Please enter the project area!' }]}
        >
          <Input placeholder="Enter project area" />
        </Form.Item>

        {/* <Form.Item
          name="project_cost_minimum"
          label="Project Cost Minimum"
          rules={[{ required: true, message: 'Please enter the minimum project cost!' }]}
        >
          <InputNumber min={0} placeholder="Enter minimum project cost" style={{ width: '100%' }} />
        </Form.Item> */}

        <Form.Item
          name="project_cost"
          label="Project Cost"
          rules={[{ required: true, message: 'Please enter the project cost!' }]}
        >
          <InputNumber min={0} placeholder="Enter project cost" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="project_year"
          label="Project Year"
          rules={[{ required: true, message: 'Please enter the project year!' }]}
        >
          <InputNumber min={0} placeholder="Enter project Year" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select the project status!' }]}
        >
          <Select placeholder="Select project status">
            <Option value="Designing">Designing</Option>
            <Option value="Pending Approval">Pending Approval</Option>
            <Option value="Approved">Approved</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="project_theme"
          label="Project Theme"
          rules={[{ required: true, message: 'Please enter the block!' }]}
        >
          <Input placeholder="Enter theme" />
        </Form.Item>

        <Form.Item
          name="recommended"
          label="Is Recommended?"
        // rules={[{ required: true, message: 'Please select the project status!' }]}
        >
          <Select placeholder="Select">
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Rooms">
          {rooms.map((room) => (
            <div>
              <Form.Item
                name={`room_title_${room.id}`}
                label="Room Type"
                initialValue={room.room_type_data.title}
                rules={[{ required: true, message: 'Please enter the room type title!' }]}
              >
                <Input placeholder="Enter room type title" />
              </Form.Item>
              <Form.Item label="Image">
                <img
                  src={room.room_type_data.image}
                  alt={room.room_type_data.title}
                  style={{ width: '100%', height: 'auto' }}
                />
              </Form.Item>
            </div>

          ))}
        </Form.Item>

        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;