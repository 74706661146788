import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  PieChartOutlined,
  AreaChartOutlined,
  MediumOutlined,
  ApartmentOutlined,
  RedditOutlined,
  StarOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Image, Menu, Space } from "antd";
import Sider from "antd/es/layout/Sider";
import Title from "antd/es/typography/Title";
import { useSelector } from "react-redux";
import { ThemeEnum } from "Redux/enums";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { interiokeysLogo, interiokeysWhiteLogo } from "Assets";
import { IoPersonOutline } from "react-icons/io5";

function getItem(
  label,
  key,
  icon,
  linkTo,
  children,
  type,
  allowedPermissions = []
) {
  if (!key) {
    key = linkTo || label.replace(" ", "").toLowerCase();
  }

  const itemContent = linkTo ? <Link to={linkTo}>{label}</Link> : label;

  return {
    key,
    icon,
    children,
    label: itemContent,
    type,
    allowedPermissions,
  };
}

function checkAllowedPermissions(userPermissions) {
  let new_items = [];

  for (let item of all_items) {
    if (!item.children) {
      // for no children / no drop down
      let allPermissionsPresentIniItem =
        item.allowedPermissions.filter((i) => userPermissions?.includes(i))
          .length === item.allowedPermissions.length;
      if (allPermissionsPresentIniItem) {
        new_items.push(item);
      }
    } else {
      // create an array of all the permissions
      let allPermissionsInsideDropdown = [];
      item.children.forEach((ite) => {
        ite.allowedPermissions.forEach((p) => {
          allPermissionsInsideDropdown.push(p);
        });
      });

      // determine if this dropdown should be shown
      let showDropdown = false;
      for (let alp of allPermissionsInsideDropdown) {
        if (userPermissions?.includes(alp)) {
          showDropdown = true;
          break;
        }
      }

      // find the children that are to be shown
      let dropdownItem = { ...item };
      if (showDropdown) {
        let newChildren = [];
        for (let child of dropdownItem.children) {
          let allPermissionsPresentIniItem =
            child.allowedPermissions.filter((i) => userPermissions?.includes(i))
              .length === child.allowedPermissions.length;
          if (allPermissionsPresentIniItem) {
            newChildren.push(child);
          }
        }
        if (newChildren.length > 0) {
          dropdownItem.children = newChildren;
          new_items.push(dropdownItem);
        }
      }
    }
  }

  return new_items;
}

const all_items = [
  getItem("Dashboard", "dashboard", <PieChartOutlined />, "/dashboard", null, null, ["dashboard:view"]),
  getItem("Marketing", "marketing", <AreaChartOutlined />, null, [
    getItem("Testimonials", null, null, "/marketing/testimonials", null, null, ["testimonials:view"]),
    getItem("Review Ratings", null, null, "/marketing/review-ratings", null, null, ["reviewratings:view"]),
    getItem("Email List", null, null, "/marketing/news-letter-list", null, null, ["newsletter:view"]),
    getItem("Advertisements", null, null, "/marketing/advertisements", null, null, ["advertise:view"]),
  ]),
  getItem("Master", "master", <MediumOutlined />, null, [
    getItem("Product Categories", null, null, "/master/product-categories", null, null, ["productcategories:view"]),
    getItem("Product Types", null, null, "/master/product-types", null, null, ["producttypes:view"]),
    getItem("Product Sub Types", null, null, "/master/product-sub-types", null, null, ["productsubtypes:view"]),
    getItem("Products", null, null, "/master/products", null, null, ["product:view"]),
    getItem("Room Categories", null, null, "/master/room-categories", null, null, ["roomcategories:view"]),
    getItem("Room Types", null, null, "/master/room-types", null, null, ["roomtypes:view"]),
    getItem("Project Types", null, null, "/master/project-types", null, null, ["projecttypes:view"]),
  ]),
  getItem("Brands", "brands", <StarOutlined />, null, [
    getItem("Brands", null, null, "/brands/brands", null, null, ["brands:view"]),
    getItem("Brand Categories", null, null, "/brands/brand-categories", null, null, ["brandcategories:view"]),
  ]),
  getItem("Builder", "builder", <ApartmentOutlined />, null, [
    getItem("Builders Info", null, null, "/builder/builders", null, null, ["builders:view"]),
    getItem("Properties", null, null, "/builder/properties", null, null, ["properties:view"]),
    // getItem("Block Layouts", null, null, "/builder/property-blocks", null, null, ["blocklayout:view"]),
  ]),
  getItem("Architect", "architect", <UserOutlined />, null, [
    getItem("Architects Info", null, null, "/architect/architects", null, null, ["architects:view"]),
    getItem("Project Details", null, null, "/architect/project-details", null, null, ["projectdetails:view"]),
    getItem("Room Details", null, null, "/architect/room-details", null, null, ["roomdetails:view"]),
  ]),
  getItem("Order", null, <MdOutlineShoppingCartCheckout />, null, [
    getItem("Orders", null, null, "/order/orders", null, null, ["orders:view"]),
    getItem("Payments", null, null, "/order/payments", null, null, ["payments:view"]),
    getItem("Invoices", null, null, "/order/invoices", null, null, ["invoices:view"]),
  ]),
  getItem("Enquiries", null, <MessageOutlined />, null, [
    getItem("Brand Enquiries", null, null, "/enquiry/brand-enquiries", null, null, ["brandenquiries:view"]),
    getItem("Product Enquiries", null, null, "/enquiry/product-enquiries", null, null, ["productenquiries:view"]),
    getItem("Consultations", null, null, "/enquiry/consultation", null, null, ["orders:view"]),
  ]),
  getItem("Platform Users", "platform-users", <UserOutlined />, null, [
    getItem("Users", null, null, "/platform-users/users", null, null, ["users:view"]),
    getItem("Permissions", null, null, "/platform-users/permissions", null, null, ["permissions:view"]),
    getItem("Roles", null, null, "/platform-users/roles", null, null, ["roles:view"]),
  ]),
];

const SideBar = ({ trigger, collapsible, collapsed }) => {
  const location = useLocation();
  const { pathname } = location;
  const currentTheme = useSelector((store) => store.utilities.theme);

  const [defaultOpenKeyState, setDefaultOpenKey] = useState([]);
  const [defaultSelectedKeyState, setDefaultSelectedKey] = useState([]);

  const user = useSelector((state) => state.auth.user);
  // console.log({ all_items })
  const filtered_items = checkAllowedPermissions(user.user_permissions);

  useEffect(() => {
    let defaultOpenKey = [];
    if (pathname?.includes("master")) {
      defaultOpenKey = ["master"];
    } else if (pathname?.includes("clients")) {
      defaultOpenKey = ["clients"];
    } else if (pathname?.includes("platform")) {
      defaultOpenKey = ["platform-users"];
    } else if (pathname?.includes("theatres")) {
      defaultOpenKey = ["theatres"];
    } else if (pathname?.includes("shows")) {
      defaultOpenKey = ["shows"];
    } else if (pathname?.includes("coupon")) {
      defaultOpenKey = ["coupons"];
    } else if (pathname?.includes("food")) {
      defaultOpenKey = ["food"];
    } else if (pathname?.includes("order")) {
      defaultOpenKey = ["order"];
    } else {
      defaultOpenKey = ["dashboard"];
    }
    setDefaultOpenKey(defaultOpenKey);
    // console.log({ defaultOpenKey });

    if (pathname?.includes("theatre")) {
      // console.log({ pathname })
      if (pathname?.includes("users")) {
        setDefaultSelectedKey("/theatres/users");
      } else if (pathname?.includes("channels")) {
        setDefaultSelectedKey("/theatres/channels");
      } else if (pathname?.includes("halls")) {
        setDefaultSelectedKey("/theatres/halls");
      } else if (pathname?.includes("seat-types")) {
        setDefaultSelectedKey("/theatres/seat-types");
      } else if (pathname?.includes("tax")) {
        setDefaultSelectedKey("/theatres/tax");
      } else {
        setDefaultSelectedKey("/theatres");
      }
    } else {
      setDefaultSelectedKey(pathname);
    }
  }, [pathname]);

  return (
    <Sider
      theme={currentTheme}
      trigger={trigger}
      collapsible={collapsible}
      collapsed={collapsed}
      style={{ maxHeight: "100%", overflow: "auto" }}
    >
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Title
          level={collapsed ? 5 : 3}
          type="secondary"
          style={{ color: currentTheme === ThemeEnum.DARK ? "#fff" : "#000" }}
        >
          {currentTheme === ThemeEnum.DARK ? (
            <Image
              src={interiokeysWhiteLogo}
              preview={false}
              style={{ maxWidth: 150 }}
            />
          ) : (
            <Image
              src={interiokeysLogo}
              preview={false}
              style={{ maxWidth: 150 }}
            />
          )}
        </Title>
      </Space>
      {Array.isArray(defaultOpenKeyState) && defaultOpenKeyState.length > 0 ? (
        <Menu
          defaultSelectedKeys={[defaultSelectedKeyState]}
          selectedKeys={defaultSelectedKeyState}
          defaultOpenKeys={defaultOpenKeyState}
          mode="inline"
          theme={currentTheme}
          items={filtered_items}
        />
      ) : (
        <></>
      )}
    </Sider>
  );
};
export default SideBar;
