import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons";
import axios from 'axios';
import { Option } from 'antd/es/mentions';

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [categories, setCategories]=useState(null)

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  const fetchProductCategories = async () => {
    try {
      const response = await axios.get("/product/product-category/");
      console.log(response);
      setCategories(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching produc list");
    }
  };

  useEffect(()=>{
    fetchProductCategories()
  },[])

  console.log(categories);
  

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({
      apiUrl: apiUrl, data: val, headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({
      apiUrl, id: currentEditViewFormId, data: val, headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    // val.image = image;

    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
      form.resetFields();
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
      form.resetFields();
    } else {
      form.resetFields();
      console.log("No suitable mode found");

    }
  };
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        {/* <Form.Item
        label="Title"
        name="title"
        rules={[
          { required: true, message: 'Please input the title!' },
        ]}
      >
         <Input placeholder="Please enter a type" />
      </Form.Item> */}
        <Form.Item
          label="Title"
          name="title"
          rules={[
            { required: true, message: 'Please input the title!' },
          ]}
        >
          <Input placeholder="Please enter a type" />
        </Form.Item>
        <Form.Item
          label="Product Category"
          name="product_category"
          rules={[{ required: true, message: "Please select a product category!" }]}
        >
          <Select placeholder="Select a product category">
            {categories?.map((type) => (
              <Option key={type.validue} value={type.id}>
                {type.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          label="Image"
          rules={[{ required: true, message: "Please upload an image!" }]}>
          <input type="file" id="imageUpload" required={currentFormMode === FormModesEnum.ADD} onChange={handleImageChange} />
        </Form.Item> */}
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;