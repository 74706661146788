import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Consultation/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useState } from "react";
import { useSelector } from "react-redux";

const apiUrl = "/order/orders";
const headerTitle = "Consultations";
const editPermission = "orders:edit";
const deletePermission = "orders:delete";
const addPermission = "orders:add"

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit', // 29
      month: 'short', // Sep
      year: 'numeric', // 2024
      hour: '2-digit', // 03
      minute: '2-digit', // 34
      hour12: true, // am/pm format
    }).format(date);
  };
  

const columns = [
    {
        title: 'Service Type',
        dataIndex: 'service_type',
        key: 'service_type',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Project Type',
        dataIndex: 'project_type',
        key: 'project_type',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Name',
        dataIndex: 'user_data',
        key: 'user_data',
        render: (userData) => userData?.full_name || ''
    },
    {
        title: 'Email',
        dataIndex: 'user_data',
        key: 'user_data',
        render: (userData) => userData?.email || ''
    },
    {
        title: 'Requirement Message',
        dataIndex: 'requirement_msg',
        key: 'requirement_msg',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        // sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        render: (created_at) => formatDate(created_at),
      },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, updated: true, action: true,
})
]

const ConsultationEnquiriesPage = () => {
    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);


    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ConsultationEnquiriesPage;